body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  min-height: 100vh !important;
}
.ant-menu-dark.ant-menu-horizontal {
  display: inline-block;
  margin-left:40px;
  max-width: 500px;
}
.ant-layout-header{
  padding: 5px 0;
  height: 77px;
}
.ant-layout-header img {
  margin-right: 30px;
  margin-left: 30px;

}
.ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-tabs-ink-bar,
.ant-switch-checked   {
  background-color: #ffd204!important;
}
.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #000!important;
  font-weight: bold;
}
.ant-switch:after,.ant-tabs-tabpane {
  padding-bottom: 15px;
}

/* .menu-content-checkbox,.menu-content-radio{
  background: #999;
} */
.ant-layout-content{
  margin-bottom: 70px!important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color:#ffd204!important;
  border-color: #ffd204!important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #ffd204!important;
}
.ant-radio-inner:after {
  background-color:#ffd204!important;
}

.flex-space{
    flex: 1 1 auto;
}

.main-header {
    margin: 0px -50px;
}

.header {
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    background: #001529;
}

.sidebar.navbar-nav
{
    width: 22rem !important;
    padding: 15px 20px;
    background-color: #ffd204;
    min-height: 100vh;
}

.sidebar.navbar-nav li a
{
    color: #000;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: bold;
}

.header button
{
    padding: 0 25px;
    font-size: 18px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: bold;
    background: #ffd204;
    border-color: #ffd204;
}

#wrapper #content-wrapper
{
    overflow-x: hidden;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 80px;
    background-color: #e7e7e7;
}

#wrapper{
    display: flex;
}
.loadingPanel {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000000 url('/assets/Images/loader.gif') 50% 50% no-repeat;
    opacity: 0.8;
}
.loadingPanel.d-block{
    display: block;
}
.loadingPanel.d-none{
    display: none;
}
.menu-link a,
.manage-store a {
    color: #000;
    text-decoration: underline;
}
.manage-store .ant-select-selection{
    border: 1px solid #000;
}
.base-day-card {
    margin: 0 30px;
}

.base-day-card .ant-card-body {
    padding: 15px;
}

.base-day-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.base-day-header span {
    font-size: 12px;
}

.base-comp {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
}

.icon-minus {
    position: absolute;
    right: 10px;
    font-size: 18px;
    color: #f00;
    cursor: pointer;
}

.base-ticket {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.btn-submit {
    display: flex;
    justify-content: center;
    margin: 15px 15px auto 0;
    float: right;
    width: 150px;
}

.btn-submit button {
    width: 100%;
    background: #666;
    border: none;
}

.btn-submit button:hover, .btn-submit button:active {
    background: #e6e6e6;
    color: #333;
}

.add-block-time{
    display: flex;
    justify-content: center;
    align-self: center;
    font-weight: 600;
    cursor: pointer;
}
.add-block-time i{
    margin-top: 3px;
}

.day-of-week {
    text-transform: capitalize;
    font-weight: 900;
}

.base-ticket,.day-status span{
    font-weight: 900;
}
.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
}

.search-bar {
    flex: 1 1;
}

.blank-space {
    flex: 1 1;
}

.title-store {
    text-align: center;
    flex: 2 1;
}

.tab-content {
    padding: 0 15px;
}

.tab-content .ant-tabs-tab {
    background: #e6e6e6;
    border-radius: 5px 5px 0 0;
    color: #5a6268;
    border: 1px solid #5a6268;
    min-width: 120px;
    margin: 0 auto;
    text-align: center;
}

.tab-content .ant-tabs-tab-active {
    background: #333;
    border-radius: 5px 5px 0 0;
    color: #fff;
    margin-right: 0;
    border: 1px solid #5a6268;
}

.tab-content .ant-tabs-nav .ant-tabs-tab:hover {
    color: #6c757d;
}

.tab-content .ant-tabs-nav .ant-tabs-tab:active {
    border-bottom: none;
}

.store-switch span {
    margin: 0 5px;
    font-size: 12px;
}

.setting-content {
    display: flex;
}

.left-side {
    flex: 1 1;
    border-right: 1px solid #333;
    padding: 15px 30px;
}

.left-side p>span {
    font-weight: bold;
}

.right-side {
    flex: 1 1;
    padding: 15px 30px;
}

.setting-header {
    display: flex;
    justify-content: space-between;
}

.footer-bottom {
    margin: 0 auto;
    text-align: center;
}

.integrate {
    display: flex;
    justify-content: space-between;
}

.btn {
    display: flex;
    justify-content: flex-end;
}

.btn button {
    background: #666;
    border: none;
}

.btn-delete button {
    background: #666;
    border: none;
    color: #fff;
}

.btn-delete button:hover {
    background: #dedede;
    color: #000;
}

.footer-bottom p {
    font-size: 12px;
}

.base-tab {
    display: flex;
    flex-wrap: wrap;
}

.btn-delete {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.menu-management{
    display: flex;
    justify-content: space-between;
}

.menu-content-radio{
    flex: 1 1;
    border-right: 1px solid #a1a1a1;
    padding: 20px 3%;
}

.menu-content-radio > div{
    display: block;
}

.save-btn button{
    width: 202px;
    float: right;
}

.menu-content-radio > div label{
    width: 100%;
}

.menu-content-checkbox{
    flex: 1 1;
    padding: 20px 3%;
}

.menu-management .menu-content-radio h5{
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
}

.menu-content-checkbox .text-block h5{
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
}

.save-btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}
.counter{
    display: flex;
    justify-content: space-between;
}

.counter input[type="text"]{
    width: auto;
    margin-bottom: 10px;
}

/* Example Styles for React Tags*/
#app {
    padding: 40px;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.file-upload {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}
.update {
    color: #212123;
}
.success {
    color: #1b691a;
}
.error {
    color: #B71215;
}
.base-day-card {
    margin: 0 30px;
}

.base-day-card .ant-card-body {
    padding: 15px;
}

.base-day-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.base-day-header span {
    font-size: 12px;
}

.base-comp {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
}

.icon-minus {
    position: absolute;
    right: 10px;
    font-size: 18px;
    color: #f00;
    cursor: pointer;
}

.base-ticket {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.btn-submit {
    display: flex;
    justify-content: center;
    margin: 15px 15px auto 0;
    float: right;
    width: 150px;
}

.btn-submit button {
    width: 100%;
    background: #666;
    border: none;
}

.btn-submit button:hover, .btn-submit button:active {
    background: #e6e6e6;
    color: #333;
}

.add-block-time{
    display: flex;
    justify-content: center;
    align-self: center;
    font-weight: 600;
    cursor: pointer;
}
.add-block-time i{
    margin-top: 3px;
}

.day-of-week {
    text-transform: capitalize;
    font-weight: 900;
}

.base-ticket,.day-status span{
    font-weight: 900;
}
.new-store{
    margin-top: 30px;
}
.new-store h1{
    font-size: 18px;
    font-weight: 400;
    color: #1b1e21;
    border-bottom: 1px solid #1b1e21;
}
.radio-menu .ant-radio-group{
    display: flex;
    flex-direction: column;
}
.store-toggle .day-status-o{
    flex: 1 1;
}
.day-status-o span{
    font-weight: 100;
    font-size: 12px;
}
.day-status span{
    font-weight: 600;
    font-size: 12px;
}
.store-toggle h5{
    text-align: left;
}
.base-content{
    background: #333;
    padding-bottom: 15px;
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
}
.vertical-alignment-helper {
    height: 100vh;
    background-image: url("/assets/Images/DDS_BG.jpg");
}

a:focus,
a:hover {
    color: #FFD401;
    text-decoration: none;
    outline: none !important;
}

.btn {
    font-size: 10px;
    padding: 0.3rem;
    white-space: normal;
}

.m0 {
    margin: 0;
}

.p0 {
    padding: 0;
}

.p-t-50 {
    padding-top: 50px;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.top-right {
    position: absolute;
    right: 5px;
    top: 0;
}

/* Form CSS */
.form-control {
    padding: 15px 10px;
    font-size: 14px;
    color: #1c1c1c;
    background-color: #eae8e2;
    border: 0;
    height: 45px;
    border-radius: 0;
}

.btn.btn-save,
.btn-save {
    background: transparent url('/assets/Images/cta-yellow.svg') center center no-repeat;
    /*min-width: 201px;*/
    min-height: 47px;
    font-size: 24px;
    color: #000;
    text-transform: uppercase;
    padding: 10px 20px;
    font-weight: 600;
    width: auto;
    background-size: cover;
}

.btn-save:focus,
.btn-save:hover {
    text-decoration: none;
    background-image: url('/assets/Images/cta-white.svg');
}

.modal-dialog {
    width: 450px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100vh;
}

.modal-dialog .logo {
    background: url('/assets/Images/logo-gomex.svg') no-repeat, none;
    background-size: contain;
    display: block;
    max-width: 110px;
    height: 110px;
    width: 110px;
    position: absolute;
    top: -50px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
}

.login-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.modal-body {
    padding-top: 80px;
}
.login-form .panel-body {
    margin-top: 18px;
}
.modal-dialog .login-form h2 {
    font-size: 24px;
    font-weight: 600;
    color: #ffd401;
    margin-bottom: 0;
}
.login-content {
    margin: 0 50px;
}
.login-content .form-group .input-group input {
    color: #fff;
    height: 45px;
    background-color: #000;
    border: 1px solid #000;
    padding-left: 40px;
}

.save-btn{
    display: flex;
    justify-content: center;
    margin: 25px auto 0;
}
