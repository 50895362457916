.new-store{
    margin-top: 30px;
}
.new-store h1{
    font-size: 18px;
    font-weight: 400;
    color: #1b1e21;
    border-bottom: 1px solid #1b1e21;
}
.radio-menu .ant-radio-group{
    display: flex;
    flex-direction: column;
}
.store-toggle .day-status-o{
    flex: 1;
}
.day-status-o span{
    font-weight: 100;
    font-size: 12px;
}
.day-status span{
    font-weight: 600;
    font-size: 12px;
}
.store-toggle h5{
    text-align: left;
}
.base-content{
    background: #333;
    padding-bottom: 15px;
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
}