body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout {
  min-height: 100vh !important;
}
.ant-menu-dark.ant-menu-horizontal {
  display: inline-block;
  margin-left:40px;
  max-width: 500px;
}
.ant-layout-header{
  padding: 5px 0;
  height: 77px;
}
.ant-layout-header img {
  margin-right: 30px;
  margin-left: 30px;

}
.ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-tabs-ink-bar,
.ant-switch-checked   {
  background-color: #ffd204!important;
}
.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #000!important;
  font-weight: bold;
}
.ant-switch:after,.ant-tabs-tabpane {
  padding-bottom: 15px;
}

/* .menu-content-checkbox,.menu-content-radio{
  background: #999;
} */
.ant-layout-content{
  margin-bottom: 70px!important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color:#ffd204!important;
  border-color: #ffd204!important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #ffd204!important;
}
.ant-radio-inner:after {
  background-color:#ffd204!important;
}
