.flex-space{
    flex: 1 1 auto;
}

.main-header {
    margin: 0px -50px;
}

.header {
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    background: #001529;
}

.sidebar.navbar-nav
{
    width: 22rem !important;
    padding: 15px 20px;
    background-color: #ffd204;
    min-height: 100vh;
}

.sidebar.navbar-nav li a
{
    color: #000;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: bold;
}

.header button
{
    padding: 0 25px;
    font-size: 18px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: bold;
    background: #ffd204;
    border-color: #ffd204;
}

#wrapper #content-wrapper
{
    overflow-x: hidden;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 80px;
    background-color: #e7e7e7;
}

#wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}