.menu-management{
    display: flex;
    justify-content: space-between;
}

.menu-content-radio{
    flex: 1;
    border-right: 1px solid #a1a1a1;
    padding: 20px 3%;
}

.menu-content-radio > div{
    display: block;
}

.save-btn button{
    width: 202px;
    float: right;
}

.menu-content-radio > div label{
    width: 100%;
}

.menu-content-checkbox{
    flex: 1;
    padding: 20px 3%;
}

.menu-management .menu-content-radio h5{
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
}

.menu-content-checkbox .text-block h5{
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
}

.save-btn {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}
.counter{
    display: flex;
    justify-content: space-between;
}

.counter input[type="text"]{
    width: auto;
    margin-bottom: 10px;
}

/* Example Styles for React Tags*/
#app {
    padding: 40px;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.file-upload {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}